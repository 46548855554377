import { render, staticRenderFns } from "./ExamExercises.vue?vue&type=template&id=68ed3401&scoped=true&"
import script from "./ExamExercises.vue?vue&type=script&lang=js&"
export * from "./ExamExercises.vue?vue&type=script&lang=js&"
import style0 from "./ExamExercises.vue?vue&type=style&index=0&id=68ed3401&lang=scss&scoped=true&"
import style1 from "./ExamExercises.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ed3401",
  null
  
)

export default component.exports