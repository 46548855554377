<template>
  <div class="bread-crumb">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item></el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",

  data() {
    return {};
  },

  components: {},
  computed: {},
  watch: {},

  methods: {},
};
</script>
<style lang="scss" scoped></style>
